import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Divider, Modal, Select, Input, message, Button } from "antd";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// api
import favorites from "../../../api/favorites";

// helpers
import { returnAddItemToFavouritesDataLayer } from "../../../helpers";

// constants
import {
  MESSAGE_EMPTY_FIELD,
  MESSAGE_REQUIRED_FIELD,
  MESSAGE_TRY_AGAIN,
} from "../../../constants";

// styles
import "../../../assets/styles/items/add-to-favorite-modal.scss";

function AddToFavoriteModal({
  itemRef,
  isOpen,
  onClose,
  onSuccess,
}) {
  const [favoritesList, setFavoritesList] = useState([]);
  const [createNewGroup, setCreateNewGroup] = useState(false);

  const validationSchema = yup.object().shape({
    selectedGroup: yup
      .array()
      .min(1, MESSAGE_REQUIRED_FIELD)
      .required(MESSAGE_REQUIRED_FIELD),
    newGroupName: createNewGroup
      ? yup.string().nullable(true).required(MESSAGE_EMPTY_FIELD)
      : yup.string().nullable(true).notRequired(),
  });

  const {
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectedGroup: [],
      newGroupName: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const { selectedGroup, newGroupName } = watch();

  useEffect(() => {
    favorites.getFavoriteGroups({ pageSize: 100 }).then(({ results }) => {
      const formatted = results.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFavoritesList(formatted);
    });
  }, []);

  const onSubmit = async (data) => {
    await favorites
      .addItemToFavoriteGroups(itemRef, { ids: data.selectedGroup })
      .catch(() => message.error(MESSAGE_TRY_AGAIN));

    onClose();
    returnAddItemToFavouritesDataLayer();
    onSuccess?.();
  };

  const handleCreateNewGroup = () => {
    favorites
      .postFavoriteGroups({ name: newGroupName })
      .then((resp) => {
        setFavoritesList([
          ...favoritesList,
          { value: resp.id, label: resp.name },
        ]);
        setValue("selectedGroup", [...selectedGroup, resp.id]);
        setValue("newGroupName", null);
        setCreateNewGroup(false);
      })
      .catch(() => message.error(MESSAGE_TRY_AGAIN));
  };

  const onSelect = (e) => {
    setValue("selectedGroup", e);

    if (errors.newGroupName) {
      clearErrors("newGroupName");
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      className="add-to-favorite-modal"
      onOk={handleSubmit(onSubmit)}
      cancelButtonProps={{ className: "add-to-favorite-modal--cancel" }}
      title={
        <img
          src={`${process.env.REACT_APP_IMAGES_URL}loginLALogo.svg`}
          alt="LoveAntiques"
          title="LoveAntiques"
        />
      }
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="add-to-favorite-modal--form"
      >
        <div>Favourite groups</div>
        <Select
          options={favoritesList}
          value={selectedGroup}
          mode="multiple"
          onChange={onSelect}
          placeholder="Select group"
          className="add-to-favorite-modal--select"
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0 0" }} />
              <div style={{ padding: "10px 12px 8px" }}>
                {createNewGroup ? (
                  <>
                    <Input
                      name="name"
                      placeholder="New group name"
                      value={newGroupName}
                      onChange={(e) => setValue("newGroupName", e.target.value)}
                    />
                    <div className="add-to-favorite-modal--btn-group">
                      <Button
                        className="add-to-favorite-modal--btn"
                        onClick={() => setCreateNewGroup(false)}
                      >
                        Cancel
                      </Button>
                      <Button onClick={handleCreateNewGroup}>Create</Button>
                    </div>
                  </>
                ) : (
                  <p
                    className="add-to-favorite-modal--create-new-label"
                    onClick={() => setCreateNewGroup(true)}
                  >
                    Create new group
                  </p>
                )}
              </div>
            </>
          )}
        />
        {(!!errors.newGroupName || !!errors.selectedGroup) && (
          <p className="input-error add-to-favorite-modal--input-error">
            {errors?.newGroupName?.message || errors?.selectedGroup?.message}
          </p>
        )}
        <p className="add-to-favorite-modal--text">
          Select the favorite group to which the item will be added
        </p>
      </form>
    </Modal>
  );
}

AddToFavoriteModal.propTypes = {
  itemRef: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddToFavoriteModal;
